<template>
  <div>
    <v-container v-if="transactions.length > 0" class="my-6">
      <div class="mt-4" v-for="(item, i) in transactions" :key="i">
        <v-card class="pa-4 grey lighten-5">
          <v-row no-gutters>
            <v-col class="d-flex">
              <div class="d-flex flex-column">
                <div class="d-flex font-weight-bold mb-1 title">
                  <div v-if="item.shipping_address.last_name" class="mr-1">
                    {{ item.shipping_address.last_name }},
                  </div>
                  {{ item.shipping_address.first_name }}
                </div>

                <div class="mb-2 body-2">
                  {{ item.shipping_address.address }},
                  {{ item.shipping_address.province }},
                  {{ item.shipping_address.postal_code }},
                  {{ item.shipping_address.country }}
                </div>

                <div class="caption">
                  {{ item.created_at | dateFormat }}
                </div>

                <!-- {{ item }}/ -->
              </div>
              <v-spacer></v-spacer>
              <v-chip
                v-if="item.timeline[0].status === 'ORDER'"
                color="secondary"
                dark
                small
                >Waiting for approve</v-chip
              >
              <v-chip v-if="item.status === 'REJECT'" color="red" dark small
                >Transaction reject</v-chip
              >
              <v-chip
                v-if="item.status === 'APPROVE'"
                color="primary"
                dark
                small
                >Transaction approved</v-chip
              >
              <v-chip
                v-if="item.status === 'PROCESS'"
                color="primary"
                dark
                small
                >Your order is being processed</v-chip
              >
              <v-chip v-if="item.status === 'READY'" color="primary" dark small
                >Waiting for delivery process</v-chip
              >
              <v-chip
                v-if="item.status === 'SHIPPING'"
                color="primary"
                dark
                small
                >Sended</v-chip
              >
            </v-col>
          </v-row>
          <div class="d-flex mt-2 justify-space-between align-center">
            <span
              >Invoice: <b class="primary--text">{{ item.invoice }}</b></span
            >
            <div class="d-flex align-center">
              <div
                v-if="
                  item.status === 'PROCESS' &&
                  getAllPrice(item) &&
                  !statusProcess(item.timeline[0])
                "
                class="mr-2 black--text font-weight-bold"
              >
                Please complete payment now!
              </div>
              <v-btn outlined @click="handleSetPayment(item)">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </div>
          </div>

          <v-divider v-if="item.openDetail" class="my-4"></v-divider>

          <v-card v-if="item.openDetail" flat class="pa-4">
            <div
              class="mb-4 font-weight-bold title d-flex justify-space-between align-baseline"
            >
              Products:
              <div class="title">
                Total:
                <b class="primary--text"
                  >${{ item.total_temp ? item.total_temp : item.total }}</b
                >
              </div>
            </div>
            <v-row>
              <v-col
                v-for="product in item.items"
                :key="product.id"
                cols="12"
                :sm="item.items.length === 1 ? 12 : 6"
              >
                <v-card outlined class="pa-2">
                  <v-row>
                    <v-col cols="4" sm="2" align-self="center">
                      <v-img
                        :src="product.item.image[0]"
                        aspect-ratio="1"
                        style="border: 1px solid #c3c3c3; border-radius: 8px"
                      ></v-img>
                    </v-col>
                    <v-col cols="8" sm="10">
                      <div class="body-1">{{ product.item.plant }}</div>
                      <div class="font-weight-bold d-flex">
                        <div>{{ product.quantity }} pcs</div>
                        <v-spacer></v-spacer>
                        <span class="title"> ${{ product.price }} </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-divider
            v-if="item.openDetail && item.status !== 'ORDER'"
            class="my-4"
          ></v-divider>

          <div v-if="item.openDetail" class="d-flex justify-center flex-column">
            <!-- APPROVED -->
            <div v-if="item.status === 'APPROVE' && !getAllPrice(item)">
              <div class="mb-2 align-start">
                <div class="font-weight-bold body-1">
                  Transaction was successfully approved.
                </div>
                <div class="body-2">
                  We will calculate your order, please wait!
                </div>
              </div>
            </div>

            <!-- PAYMENT -->
            <div
              v-if="
                item.status === 'PROCESS' && !statusProcess(item.timeline[0])
              "
            >
              <div class="mb-2 align-start text-center">
                <div class="font-weight-bold body-1">
                  Transaction was successfully approved.
                </div>
                <div class="body-2">
                  To continue the shipping process, please make payment
                  immediately according to the following invoice.
                </div>
              </div>

              <v-sheet
                width="60%"
                class="mb-2 mt-6 align-content-center mx-auto"
              >
                <v-card flat color="#f1f3f4" class="pa-4">
                  <div class="d-flex justify-space-between">
                    Subtotal
                    <div class="font-weight-bold">${{ item.subtotal }}</div>
                  </div>
                  <div class="d-flex justify-space-between">
                    Shipping Cost
                    <div class="font-weight-bold">
                      ${{ item.shipping_cost }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    Handling
                    <div class="font-weight-bold">${{ item.handling }}</div>
                  </div>
                  <div class="d-flex justify-space-between">
                    Permit
                    <div class="font-weight-bold">${{ item.premit }}</div>
                  </div>
                  <div
                    v-if="item.discount"
                    class="d-flex justify-space-between"
                  >
                    Discount
                    <div class="font-weight-bold">${{ item.discount }}</div>
                  </div>

                  <v-divider class="my-4"></v-divider>
                  <div class="d-flex justify-space-between">
                    Total
                    <div class="title font-weight-black">
                      USD ${{ Number(item.total).toFixed(2) }}
                    </div>
                  </div>
                </v-card>

                <div class="mt-6 mb-3 font-weight-bold body-1 text-center">
                  Send payment in the following method.
                </div>

                <v-card flat>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        @click="setPaymentMethod('paypal', i)"
                        >Paypal (Fee:
                        {{ env.paypal_fee }}%)</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="#f1f3f4" class="pt-4">
                        <div
                          v-if="item.total_temp"
                          class="d-flex justify-space-between"
                        >
                          PayPal administration Fee
                          <div class="font-weight-bold">
                            ${{
                              ((env.paypal_fee / 100) * item.total).toFixed(2)
                            }}
                          </div>
                        </div>
                        <div class="d-flex justify-space-between mb-4">
                          Total
                          <div class="title font-weight-black">
                            USD ${{
                              item.total_temp ? item.total_temp : item.total
                            }}
                          </div>
                        </div>
                        <div class="d-flex justify-center">
                          <PayPal
                            :client="credentials"
                            :invoice-number="item.invoice"
                            :button-style="{ size: 'large' }"
                            :amount="`${item.total_temp}`"
                            currency="USD"
                            @payment-authorized="getAuthorized"
                            @payment-completed="getComplete"
                            @payment-cancelled="getCancelled"
                          >
                          </PayPal>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        @click="setPaymentMethod('bank_transfer', i)"
                        >Bank Transfer (Fee: ${{
                          Number(env.bank_fee).toFixed(2)
                        }})</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="#f1f3f4" class="pt-4">
                        <div
                          v-if="item.total_temp"
                          class="d-flex justify-space-between"
                        >
                          Bank administration Fee
                          <div v-if="env.bank_fee" class="font-weight-bold">
                            ${{ Number(env.bank_fee).toFixed(2) }}
                          </div>
                        </div>
                        <div class="d-flex justify-space-between mb-4">
                          Total
                          <div class="title font-weight-black">
                            USD ${{
                              item.total_temp ? item.total_temp : item.total
                            }}
                          </div>
                        </div>
                        <v-card
                          v-for="bank in bankAccounts"
                          :key="bank.id"
                          outlined
                          class="pa-2 rounded"
                        >
                          <div>
                            <span
                              >{{ bank.bank }} -
                              <strong>{{ bank.account_number }}</strong></span
                            >
                            <div>({{ bank.account_name }})</div>

                            <div v-if="bank.swift_code" class="mb-2">
                              Swift Code: {{ bank.swift_code }}
                            </div>
                            <div v-if="bank.address">
                              Bank Address: {{ bank.address }}
                            </div>
                          </div>
                        </v-card>

                        <div class="mb-2 mt-2 body-2">
                          after you make a payment, please enter the proof of
                          the transaction, by pressing the button below.
                        </div>
                        <div v-if="!proofFile" class="d-flex mb-2">
                          <a class="primary--text mr-2" @click="triggerUpload"
                            >Click to upload transfer proof</a
                          >
                          <input
                            type="file"
                            id="file"
                            name="proof"
                            accept="image/*"
                            style="display: none"
                            @change="setProof"
                          />
                          <span>No picture yet</span>
                        </div>
                        <div v-if="proofFile" class="d-flex mb-2">
                          <span class="primary--text font-weight-bold">{{
                            proofFile.name
                          }}</span>
                        </div>
                        <div>
                          <v-btn
                            :disabled="!proofFile"
                            color="primary"
                            block
                            @click="proofHandler(item)"
                            >Submit</v-btn
                          >
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-sheet>

              <div class="mt-6 text-center primary--text">
                After making the payment, we will process your transaction.
              </div>
            </div>

            <div v-if="statusProcess(item.timeline[0])">
              <v-card flat color="#f1f3f4" class="pa-4">
                <div class="d-flex justify-space-between">
                  Subtotal
                  <div class="font-weight-bold">${{ item.subtotal }}</div>
                </div>
                <div class="d-flex justify-space-between">
                  Shipping Cost
                  <div class="font-weight-bold">${{ item.shipping_cost }}</div>
                </div>
                <div class="d-flex justify-space-between">
                  Handling
                  <div class="font-weight-bold">${{ item.handling }}</div>
                </div>
                <div class="d-flex justify-space-between">
                  Permit
                  <div class="font-weight-bold">${{ item.premit }}</div>
                </div>
                <div v-if="item.discount" class="d-flex justify-space-between">
                  Discount
                  <div class="font-weight-bold">${{ item.discount }}</div>
                </div>
                <div
                  v-if="item.payment_fee"
                  class="d-flex justify-space-between"
                >
                  Payment Fee
                  <div class="font-weight-bold">${{ item.payment_fee }}</div>
                </div>

                <v-divider class="my-4"></v-divider>
                <div class="d-flex justify-space-between">
                  Total
                  <div class="title font-weight-black">
                    USD ${{ item.total }}
                  </div>
                </div>
              </v-card>

              <v-row v-if="item.timeline[0].status === 'SHIPPING'" class="mt-6">
                <v-col cols="12" class="title font-weight-bold"
                  >Shipping info</v-col
                >
                <v-col cols="3"> Delivery Receipt Number</v-col>
                <v-col cols="9"> {{ item.resi }} </v-col>
                <v-col cols="3"> Courier</v-col>
                <v-col cols="9">
                  <span class="font-weight-bold">DHL</span>
                  <div>
                    For tracking your package, you can access in DHL official
                    site, and input receipt number
                    <a class="primary--text" @click="openDhlPage"
                      >https://www.dhl.com/us-en/home/tracking.html</a
                    >
                  </div>
                </v-col>
                <v-col cols="3" v-if="item.invoice_packing">
                  Delivery Invoice</v-col
                >
                <v-col cols="9" v-if="item.invoice_packing">
                  <v-img
                    :src="item.invoice_packing"
                    contain
                    class="rounded-lg"
                  />
                </v-col>
                <v-col cols="3" v-if="item.phyto">
                  Phytosanitary certification</v-col
                >
                <v-col cols="9" v-if="item.phyto">
                  <v-img :src="item.phyto" contain class="rounded-lg"
                /></v-col>
              </v-row>

              <v-divider class="my-6"></v-divider>

              <v-sheet
                v-if="item.timeline && item.timeline.length > 0"
                class="py-4 pr-4"
              >
                <div class="title text-center font-weight-bold">
                  Tracking info
                </div>
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="(t, i) in item.timeline"
                    :key="i"
                    :color="i === 0 ? 'primary' : 'secondary'"
                    small
                  >
                    <strong class="mr-2">{{ t.time | dateFormatEpoch }}</strong>
                    <div>{{ statusText(t.status) }}</div>
                  </v-timeline-item>
                </v-timeline>
              </v-sheet>
            </div>
          </div>
        </v-card>
      </div>
    </v-container>
    <v-container v-else>
      <div style="min-height: 200px" class="d-flex align-center justify-center">
        You dont have any transaction
      </div>
    </v-container>
  </div>
</template>

<script>
import {
  getMyTransaction,
  setPayment,
  setProof,
  getBankAccount,
} from "@/api/transactions";

import { uploadFile } from "@/api/master";
import moment from "moment";
import PayPal from "vue-paypal-checkout";

export default {
  components: {
    PayPal,
  },
  filters: {
    dateFormat: (value) => {
      if (!value) return "-";
      return moment(value).format("DD MMM YYYY, HH:mm");
    },
    dateFormatEpoch: (value) => {
      if (!value) return "-";
      return moment.unix(value).format("DD MMM YYYY, HH:mm");
    },
  },
  data() {
    return {
      bankAccounts: [],
      transactions: [],
      proofFile: null,
      selectedPaymentMethod: "paypal",
      selectedPayment: {
        id: null,
      },
      env: null,
      credentials: {
        sandbox:
          "AVak9_ysFBldgO1KmBsvUXTAg8y0O6N_x95i2VFG4-wA0Ok-1kxTvq-vkW26psquCgyglTtNO7yplG2f",
        // "AY7l6jODn9U5b3Lm9nAp5uSFBc9TaR0apxnjTJ-Mmpox6_-qpICeI5YQzmCpnBew-vumWbPLxNZIIp2i",
        production:
          "AfPsQ8_Fj0eA_S2C_0P9Q81miNrxk4wbmpKX7E14DvY3qgbYhSPBvJ2sRmPm3maUKHf8ehrz3kFtmiyO",
        // "Af0k7RBr8JO_-vRzlekm_uNO8b912T4DutJGoczti77X6G6jdIRi51bac-rFb_AvN3grkkZ4tkwtvYen",
      },
    };
  },
  async mounted() {
    await getBankAccount().then((res) => {
      this.bankAccounts = res.data.data;
    });

    await this.getTransactions();
    this.env = this.$store.getters.g_profile;
  },
  methods: {
    getAllPrice(item) {
      if (item.shipping_cost && item.premit && item.handling) return true;
      else return false;
    },
    statusProcess(data) {
      switch (data.status) {
        case "PAYMENT":
        case "PROCESS_PACKING":
        case "PROCESS_PHYTO":
        case "READY":
        case "SHIPPING":
          return true;
        default:
          return false;
      }
    },
    statusText(status) {
      switch (status) {
        case "APPROVE":
          return "Transaction approved";
        case "PAYMENT":
          return "Successfully made payment";
        case "PROCESS":
          return "Prepare your package";
        case "PROCESS_PACKING":
          return "We are packing your package";
        case "PROCESS_PHYTO":
          return "We are processing phytosanitary document";
        case "READY":
          return "All document is ready, waiting for sending package";
        case "SHIPPING":
          return "Your package has been sent";
        default:
          return "Transaction created";
      }
      // 'ORDER','APPROVE','PAYMENT','PROCESS','PROCESS_PACKING','PROCESS_PHYTO','READY','SHIPPING','REJECT','CANCEL'
    },
    async proofHandler(transaction) {
      if (this.proofFile) {
        this.$store.commit("GLOBAL_LOADING");
        let formData = new FormData();
        formData.append("file", this.proofFile);

        const file = await uploadFile(formData);
        const query = {
          id: transaction.id,
          payment_proof: file.data.data.path,
        };

        await setProof(query);

        setPayment({
          id: this.selectedPayment,
          payment_method: this.selectedPaymentMethod,
        })
          .then((res) => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: "Success upload proof",
                color: "success",
              });
              this.getTransactions();
            }
            this.$store.commit("GLOBAL_LOADING");
          })
          .catch(() => this.$store.commit("GLOBAL_LOADING"));
      }
    },
    triggerUpload() {
      let fileUpload = document.getElementById("file");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    setProof(e) {
      this.proofFile = null;
      setTimeout(() => {
        if (e) this.proofFile = e.target.files[0];
      }, 200);
    },
    async getTransactions() {
      this.$store.commit("GLOBAL_LOADING");
      await getMyTransaction()
        .then(({ data }) => {
          if (data.code) {
            const newTransaction = data.data.map((r) => {
              return { ...r, openDetail: false };
            });
            this.transactions = newTransaction;
          }
          this.$store.commit("GLOBAL_LOADING");
        })
        .catch(() => {
          this.$store.commit("GLOBAL_LOADING");
        });
    },
    calculateTotal() {
      let total = 0;
      this.transactions.map((r) => {
        total = total + r.total;
      });
      return total;
    },
    calculatePrice(item, idx) {
      const total = Number(item.price) * Number(item.qty);
      if (idx !== undefined) this.transactions[idx].total = total;
      return total;
    },
    getAuthorized(e) {
      console.log("getAuthorized", e);
    },
    setPaymentMethod(param, idx) {
      this.selectedPaymentMethod = param;
      const total = this.transactions[idx].total;
      const transactions = [...this.transactions];
      if (param === "paypal") {
        const fee = this.$store.getters.g_profile.paypal_fee;
        const finalFee = (fee / 100) * total;
        transactions[idx].total_temp = (
          Number(total) + Number(finalFee)
        ).toFixed(2);
      } else {
        const bankFee = this.$store.getters.g_profile.bank_fee;
        transactions[idx].total_temp = (
          Number(total) + Number(bankFee)
        ).toFixed(2);
      }

      console.log(transactions[idx].total_temp, param);

      this.transactions = transactions;
    },
    handleSetPayment(data) {
      data.openDetail = !data.openDetail;
      this.selectedPayment = data.id;
    },
    async getComplete(e) {
      if (e.state === "approved") {
        await setPayment({
          id: this.selectedPayment,
          payment_method: this.selectedPaymentMethod,
        });
        this.$store.commit("CALL_SNACKBAR", {
          msg: "Payment has been successfully made, your transaction will be processed immediately",
          color: "success",
        });
        this.getTransactions();
      } else {
        console.log(e);
      }
    },
    getCancelled(e) {
      console.log("getCancelled", e);
    },
    openDhlPage() {
      setTimeout(() => {
        window.open("www.dhl.com/us-en/home/tracking.html", "_blank");
      }, 200);
    },
  },
};
</script>
